import ImageLoader from "../ImageLoader/ImageLoader";
import { useLayoutEffect, useRef, useState, useMemo, useEffect } from "react";
import { LazyLoadImage } from 'react-lazy-load-image-component';
import { Editable, Slate, withReact } from "slate-react";
import { withHistory } from "slate-history";
import { createEditor, Transforms } from "slate";
import image from '../../Images/Regular_size/standardPicture.png';
import exclamation from '../../Images/Regular_size/exclamation.svg';
import eye from '../../Images/Regular_size/eye.svg';
import linux from '../../Images/Regular_size/linux.svg';
import windows from '../../Images/Regular_size/windows.svg';
import google from '../../Images/Regular_size/google.svg';
import eyeClose from '../../Images/Regular_size/eyeClose.svg';
import buttonUp from '../../Images/Regular_size/buttonUp.svg';
import buttonDown from '../../Images/Regular_size/buttonDown.svg';
import axios from "axios";
import { useStore } from "effector-react";
import { addressStore } from "../Store/ConfigStore";
import styles from './ProductBuilder.module.css'
import styles2 from './../Contact/Contact.module.css'
import { useLocation, useNavigate } from "react-router-dom";
import TransparentButton from "../CommonComponents/TransparentButton/TransparentButton";
import BlueButton from "../CommonComponents/BlueButton/BlueButton";
import DownloadButton from '../CommonComponents/DownloadButton/DownloadButton';
import DownloadButtonOS from "../CommonComponents/DownloadButtonOS/DownloadButtonOS";
import SliderElement from "../CommonComponents/SliderElement/SliderElement";
import PreviewEditor from "./PreviewEditor/PreviewEditor";
import { json, useParams } from "react-router";
import DoubleSidedGradient from './../CommonComponents/DoubleSidedGradient/DoubleSidedGradient';
import SlateEditor from '../CommonComponents/Editor/SlateEditor/Editor';
import SlateRenderer from '../CommonComponents/Editor/SlateRenderer/Renderer';
import ImageLinksEditor from "./ImageLinksEditor/ImageLinksEditor";

function ProductBuilder() {
    const address = useStore(addressStore);
    const [isShowModal, setIsShowModal] = useState(false);
    const [path, setPath] = useState([]);
    let { id } = useParams();
    const [productName, setProductName] = useState("");
    const [price, setPrice] = useState("");
    const [productDescription, setProductDescription] = useState("");
    const [productTextDown, setProductTextDown] = useState([
        {
            type: 'paragaph',
            children: [{ text: '' }],
            id: "0",
        },
    ]);
    const [productTextDownEditor, setProductTextDownEditor] = useState([
        {
            type: 'paragaph',
            children: [{ text: '' }],
            id: "0",
        },
    ]);

    // alert(JSON.stringify(productTextDown))
    const [productButtons, setProductButtons] = useState([{ buttonName: "Описание", content: "Контент описания", isShowText: false, isMain: true, isVisible: true, contentEditor: [{ type: 'paragaph', children: [{ text: '' }], id: "0", },] },
    { buttonName: "Состав комплекта", content: "Контент Состав комплекта", isShowText: false, isMain: true, isVisible: true, contentEditor: [{ type: 'paragaph', children: [{ text: '' }], id: "0", },] },
    { buttonName: "Дополнительно", content: "Контент Дополнительно", isShowText: false, isMain: true, isVisible: true, contentEditor: [{ type: 'paragaph', children: [{ text: '' }], id: "0", },] },
    { buttonName: "Программное обеспечение", content: "Контент ПО", isShowText: false, isMain: true, isVisible: false, contentEditor: [{ type: 'paragaph', children: [{ text: '' }], id: "0", },] },
    { buttonName: "Сертификаты", content: "Контент Сертификаты", isShowText: false, isMain: true, isVisible: false, contentEditor: [{ type: 'paragaph', children: [{ text: '' }], id: "0", },] }]);
    const [images, setImages] = useState([]);

    const [productLinks, setProductLinks] = useState([]);

    const saveHandler = () => {
        let content = {
            "productName": productName,
            "price": price,
            "productDescription": productDescription,
            "productLinks":productLinks,
            "productTextDown": productTextDown,
            "productTextDownEditor": productTextDownEditor,
            "productButtons": productButtons,
            "images": images,
            "isPrice": isPrice,
            "isOrder": isOrder
        }
        axios.post(address + `api/v1/articles/updateArticle?id=` + id, {
            "Name": null,
            "Image": null,
            "Description": null,
            "Content": [content],
            "ParentId": null
        }, {
            headers: {
                'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
            }
        }).then(res => {
            alert("Данные успешно сохранены")
        }).catch((error) => {
            alert(JSON.stringify(error))
        });
    }

    let navigate = useNavigate();
    const [update, setUpdate] = useState(0);
    const [isPrice, setIsPrice] = useState(true);
    const [isOrder, setIsOrder] = useState(true);
    const [status, setStatus] = useState(false);
    const [memText, setMemText] = useState("");
    const [memID, setMemID] = useState(0);
    const [currentVkladka, setCurrentVkladka] = useState(0);
    const [isPreviewEditor, setIsPreviewEditor] = useState(false);
    const [isLoad, setIsLoad] = useState(false);
    const [productNamePreview, setProductNamePreview] = useState("");

    const [width, setWidth] = useState(window.innerWidth);
    useEffect(() => { // хук отслеживает изменение ширины экрана
        if (!sessionStorage.getItem('token')) {
            navigate('/moderation')
        } else {
            axios.get(address + 'api/v1/articles/getArticle?id=' + id)
                .then(res => {
                    if (res.data.Content?.length !== 0 && res.data.Content?.length !== undefined) {
                        console.log(res.data)
                        setStatus(res.data.Status)
                        setPath(res.data.Path)
                        setProductNamePreview(res.data.Name)
                        setProductName(res.data.Content[0].productName)
                        setPrice(res.data.Content[0].price)
                        setProductDescription(res.data.Content[0].productDescription)
                        if (res.data.Content[0].productLinks){
                            setProductLinks(res.data.Content[0].productLinks)
                        }
                        if (res.data.Content[0]?.productTextDownEditor) {
                            setProductTextDownEditor(res.data.Content[0].productTextDownEditor)
                        } else {
                            setProductTextDownEditor([{ type: 'paragaph', children: [{ text: '' }], id: "0", },])
                        }
                        if (res.data.Content[0].hasOwnProperty('isPrice')) {
                            setIsPrice(res.data.Content[0].isPrice)
                        } else {
                            setIsPrice(true)
                        }
                        if (res.data.Content[0].hasOwnProperty('isOrder')) {
                            setIsOrder(res.data.Content[0].isOrder)
                        } else {
                            setIsOrder(true)
                        }
                        setProductButtons(res.data.Content[0].productButtons.map((x, i) => {
                            if (x?.contentEditor) {
                                return x
                            } else {
                                x["contentEditor"] = [{ type: 'paragaph', children: [{ text: '' }], id: "0", },]
                                return x
                            }
                        }))
                        setImages(res.data.Content[0].images)
                    }
                    setIsLoad(true)
                })
            const handleResize = (event) => {
                setWidth(event.target.innerWidth);
            };
            window.addEventListener('resize', handleResize);
            return () => {
                window.removeEventListener('resize', handleResize);
            };
        }
    }, []);

    const onKeyDown = e => {
        if (e.key === 'Enter') {
            let mem = [...productButtons];
            mem[memID].isShowText = false;
            mem[memID].buttonName = memText
            setProductButtons(mem)
            setMemText("")
            setMemID(0);
        }
    }


    const changeStatus = () => {
        if (status) {
            axios.post(address + 'api/v1/articles/return?id=' + id)
                .then(res => {
                    setStatus(false)
                })
        } else {
            axios.post(address + 'api/v1/articles/publish?id=' + id)
                .then(res => {
                    setStatus(true)
                })
        }
    }


    const imageMimeType = /image\/(png|jpg|jpeg)/i;
    const changeHandler = async (e) => {
        // const file = e.target.files[0];
        let mem = []
        for (let i = 0; i < e.target.files.length; i++) {
            if (e.target.files[i] !== undefined) {
                await axios.post(address + `api/v1/files/upload`, e.target.files[i], {
                    headers: {
                        'Content-Type': "image/png",
                        'Authorization': `Bearer ${sessionStorage.getItem('token')}`,
                    }
                }).then(res => {
                    mem = [...mem, res.data.FileName]
                }).catch((error) => {
                    // alert(JSON.stringify(error.message))
                });
            }
        }
        await setImages([...images, ...mem])
        return;
    }

    const [memI, setMemI] = useState(0);

    function dragStartHandler(e, word, i) {
        setMemI(i)
    }

    function dragEndHandler(e) {
        e.target.style.boxShadow = ""
    }

    function dragOverHandler(e) {
        e.preventDefault()
        e.target.style.boxShadow = "-3px 0 1px #ababab";
    }

    function dropHandler(e, word, i) {
        e.preventDefault()
        let oldWord = images[memI]
        e.target.style.boxShadow = ""
        let copy = images.filter((v, i) => i !== memI);
        copy.splice(i, 0, oldWord)
        setImages(copy)
    }

    return (
        <div>
            {!isPreviewEditor &&
                <div style={width > 1920 ? { background: '#F6F6F6', position: 'relative', display: 'flex', justifyContent: 'center' } : { background: '#F6F6F6' }}>
                    {isShowModal &&
                        <>
                            <div className={styles.shadow} onClick={() => setIsShowModal(false)}>
                                <div style={{ color: 'white', display: 'flex', flexWrap: 'wrap' }} onClick={(e) => e.stopPropagation()}>
                                    {images.map((x, i) => {
                                        return (
                                            <div style={{ margin: 10, position: 'relative', cursor: 'pointer' }}
                                                draggable={true}
                                                onDragStart={(e) => dragStartHandler(e, x, i)}
                                                onDragLeave={(e) => dragEndHandler(e)}
                                                onDragEnd={(e) => dragEndHandler(e)}
                                                onDragOver={(e) => dragOverHandler(e)}
                                                onDrop={(e) => dropHandler(e, x, i)}>
                                                <div className={styles.closeButtonWrapper} onClick={() => setImages(images.filter((y, j) => i !== j))}>
                                                    X
                                                </div>
                                                <LazyLoadImage
                                                    src={address + "uploads/" + x}
                                                    effect="blur"
                                                    width="232px"
                                                    height="220px"
                                                    style={{ objectFit: 'cover', opacity: 1 }} />
                                            </div>
                                        )
                                    })}
                                    <form className={styles.addImage} style={{ height: "180px", cursor: "pointer", padding: 10, cursor: 'pointer' }}
                                        method="post" encType="multipart/form-data">
                                        <label className="input-file" style={{ cursor: 'pointer' }}>
                                            <input style={{ opacity: 0, cursor: 'pointer', pointerEvents: 'none' }} formEncType='multipart/form-data' multiple type="file" name="file" id='image'
                                                accept='.png, .jpg, .jpeg, .webp'
                                                onChange={changeHandler}
                                            />
                                            <div>
                                                <div className={styles.addImagePlusStyle}>+</div>
                                            </div>
                                        </label>
                                    </form>
                                </div>
                            </div>
                        </>
                    }
                    <div style={width > 1920 ? { maxWidth: 1920, overflow: 'hidden' } : { overflow: 'hidden' }}>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div style={{ flex: 1 }} />
                            <div className={styles.breadcrumbsStyle}>
                                <div className={styles.breadcrumbsElement} onClick={() => {
                                    navigate("/products"); window.scrollTo(0, 0);
                                }}>
                                    Продукты
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <span style={{ marginLeft: 5, marginRight: 5 }}> {">"} </span>
                                    <div className={styles.breadcrumbsElement} onClick={() => {
                                        navigate("/products", { state: { directoryId: path[path.length - 1]?.Id } }); window.scrollTo(0, 0);
                                    }}>
                                        {(path[path.length - 1]?.Id === 2) ? "Цифровые лаборатории" : (path[path.length - 1]?.Id === 3) ? "Робототехника" : (path[path.length - 1]?.Id === 4) ? "Учебное оборудование" : (path[path.length - 1]?.Id === 5) ? "Интерактивное об-ние" : ""}
                                    </div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <span style={{ marginLeft: 5, marginRight: 5 }}> {">"} </span>
                                    <div className={styles.breadcrumbsElement} onClick={() => {
                                        navigate("/products", { state: { directoryId: path[path.length - 1]?.Id, subDirectory: { "Id": path[path.length - 2]?.Id, "Name": path[path.length - 2]?.Name, "Image": "" } } }); window.scrollTo(0, 0);
                                    }}>
                                        {path[path.length - 2]?.Name}
                                    </div>
                                </div>
                                <div style={{ display: 'flex' }}>
                                    <span style={{ marginLeft: 5, marginRight: 5 }}> {">"} </span>
                                    <div className={styles.breadcrumbsElement}>
                                        {productNamePreview}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <DoubleSidedGradient />
                        </div>
                        <div className={styles.headerProduct}>
                            <div style={{ flex: 1 }}>
                                <div className={styles.buttonWrapper} onClick={() => setIsPreviewEditor(true)}>
                                    Редактировать превью
                                </div>
                            </div>
                            <div style={{ flex: "1000px" }}>
                                <div className={styles.headerText} style={{ marginLeft: 20, marginTop: 20 }}>
                                    <input
                                        value={productName}
                                        className={styles.inputDescriptionStyle}
                                        placeholder="Введите название продукта"
                                        style={{ width: "100%" }}
                                        onChange={(e) => setProductName(e.target.value)} />
                                </div>
                                <div className={styles.headerWrap}>
                                    <div style={{ width: 315, height: 220, margin: 20, cursor: 'pointer' }} onClick={() => setIsShowModal(true)}>
                                        {/* Карусель Карусель Карусель Карусель */}
                                        <div style={{ height: '100%', display: 'flex' }}>
                                            {images[1] &&
                                                <div style={{ flex: 1 }}>
                                                    <div>
                                                        <LazyLoadImage
                                                            src={address + "uploads/" + images[1]}
                                                            effect="blur"
                                                            width="100%"
                                                            height="80px"
                                                            style={{ objectFit: 'cover' }}
                                                        />
                                                    </div>
                                                    {images[2] &&
                                                        <>
                                                            <div>
                                                                <LazyLoadImage
                                                                    src={address + "uploads/" + images[2]}
                                                                    effect="blur"
                                                                    width="100%"
                                                                    height="80px"
                                                                    style={{ objectFit: 'cover' }}
                                                                />
                                                            </div>
                                                            {images[3] &&
                                                                <div>
                                                                    <svg width="78" height="44" viewBox="0 0 78 44" fill="none" xmlns="http://www.w3.org/2000/svg">
                                                                        <rect width="44" height="78" rx="22" transform="matrix(0 -1 -1 0 78 44)" fill="#D8D8D8" fill-opacity="0.5" />
                                                                        <path d="M54.5 12.5L37.6477 36.5L23.5 12.5" stroke="#0092DD" stroke-width="3" stroke-linecap="round" stroke-linejoin="round" />
                                                                    </svg>
                                                                </div>
                                                            }
                                                        </>
                                                    }
                                                </div>
                                            }
                                            <div style={{ flex: 4, marginLeft: 5 }}>
                                                <LazyLoadImage
                                                    src={address + "uploads/" + images[0]}
                                                    effect="blur"
                                                    width="100%"
                                                    height="100%"
                                                    style={{ objectFit: 'cover' }}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div style={{ marginLeft: 20, flex: 1 }}>
                                        <div className={styles.priceText}>
                                            <span style={{ fontWeight: 600, marginRight: 10 }}>Цена:</span><input
                                                value={price}
                                                className={styles.inputPriceStyle}
                                                placeholder="Введите цену"
                                                onChange={(e) => setPrice(e.target.value)} />
                                            <span style={{ position: 'relative' }}>
                                                ₽
                                                <div className={styles.closeButton} style={{ left: 20, top: -10, cursor: 'pointer' }}
                                                    onClick={() => setIsPrice(!isPrice)}>
                                                    <LazyLoadImage
                                                        style={{ marginBottom: isPrice ? 2 : 1 }}
                                                        src={isPrice ? eye : eyeClose}
                                                        effect="blur"
                                                    />
                                                </div>
                                            </span>
                                        </div>

                                        <div className={styles.productText}>
                                            {/* {productDescription} */}
                                            <textarea
                                                value={productDescription}
                                                className={styles.inputDescriptionStyle}
                                                placeholder="Введите описание"
                                                style={{ width: "100%" }}
                                                onChange={(e) => setProductDescription(e.target.value)} />
                                        </div>
                                        <div style={{ flex: 2, marginTop: 10, marginBottom: 20, width: (width > 768) ? 220 : 300, position:'relative' }} onClick={() => { navigate("/customers"); window.scrollTo(0, 0) }}>
                                            <div className={styles.closeButton} style={{ left: (width > 768) ? 240 : 320, top: -10, cursor: 'pointer' }}
                                                onClick={(e) => {e.stopPropagation(); setIsOrder(!isOrder); }}>
                                                <LazyLoadImage
                                                    style={{ marginBottom: isOrder ? 2 : 1 }}
                                                    src={isOrder ? eye : eyeClose}
                                                    effect="blur"
                                                />
                                            </div>
                                            <BlueButton width={(width > 768) ? 196 : 280} extension={(width > 768) ? 220 : 300} text={"Заказчикам"} />
                                        </div>
                                        <ImageLinksEditor productLinks={productLinks} setProductLinks={setProductLinks}/>
                                    </div>
                                </div>
                            </div>
                            <div style={{ flex: 1 }}>
                            </div>
                        </div>
                        <div style={{ display: 'flex', marginTop: 50 }}>
                            <div style={{ flex: 1 }} />
                            <div className={styles.text} style={{ flex: "1000px" }}>
                                <div className={styles.justifier}>
                                    <div className={styles.editorWrapper}>
                                        {isLoad &&
                                            <div style={{ width: 1200 }}>
                                                <SlateEditor content={productTextDownEditor} setContent={setProductTextDownEditor} />
                                            </div>
                                        }
                                    </div>
                                </div>
                            </div>
                            <div style={{ flex: 1 }} />
                        </div>

                        <div style={{ display: 'flex', marginTop: 50 }}>
                            <div style={{ flex: 1 }} />
                            <div style={{ flex: "1000px", marginLeft: 20 }}>
                                <div style={{ display: 'flex' }}>
                                    {productButtons.map((x, i) => {
                                        return (
                                            <div className={styles.buttonWrap} style={currentVkladka === i ? { background: "rgba(216, 216, 216, 0.4)" } : {}} onDoubleClick={() => {
                                                let mem = [...productButtons];
                                                if (mem[memID].isShowText) {
                                                    mem[memID].isShowText = false;
                                                    mem[memID].buttonName = memText
                                                }
                                                setMemID(i);
                                                setMemText(x.buttonName)
                                                mem = [...productButtons];
                                                mem[i].isShowText = true;
                                                setProductButtons(mem)
                                            }} onClick={() => {
                                                // alert(JSON.stringify(productButtons))
                                                // setUpdate(update+1)
                                                setCurrentVkladka(i)
                                            }}>
                                                {!x.isMain &&
                                                    <div className={styles.closeButton} onClick={(e) => {
                                                        if (i === currentVkladka) {
                                                            setCurrentVkladka(0)
                                                        }
                                                        setProductButtons(productButtons.filter((y, j) => i !== j))
                                                        e.stopPropagation();
                                                        e.nativeEvent.stopImmediatePropagation();
                                                    }}>
                                                        x
                                                    </div>
                                                }
                                                {x.isMain &&
                                                    <div className={styles.closeButton} onClick={(e) => {
                                                        let mem = [...productButtons]
                                                        mem[i].isVisible = !mem[i].isVisible
                                                        setProductButtons(mem)

                                                        e.stopPropagation();
                                                        e.nativeEvent.stopImmediatePropagation();
                                                    }} onDoubleClick={(e) => {
                                                        e.stopPropagation();
                                                        e.nativeEvent.stopImmediatePropagation();
                                                    }}>
                                                        <LazyLoadImage
                                                            src={x.isVisible ? eye : eyeClose}
                                                            effect="blur"
                                                        />
                                                    </div>
                                                }
                                                {!x.isShowText &&
                                                    <div>{x.buttonName}</div>
                                                }
                                                {x.isShowText &&
                                                    <input
                                                        onKeyDown={onKeyDown}
                                                        value={memText}
                                                        onChange={(e) => {
                                                            setMemText(e.target.value)
                                                        }}
                                                        placeholder={"Введите название кнопки"}
                                                    />
                                                }
                                            </div>
                                        )
                                    })}
                                    <div className={styles.buttonWrap} style={{ width: 50, display: 'flex', justifyContent: 'center' }} onClick={() => {
                                        let mem = [...productButtons, { buttonName: "Новая вкладка", content: "Описание вкладки", isShowText: false, isMain: false, isVisible: true, contentEditor: [{ type: 'paragaph', children: [{ text: '' }], id: "0", },] }];
                                        if (mem[memID]?.isShowText) {
                                            mem[memID].isShowText = false;
                                            mem[memID].buttonName = memText;
                                        }
                                        setProductButtons(mem)
                                    }}>
                                        +
                                    </div>
                                </div>
                                <div style={{ background: "#0092DD", height: 1 }} />
                            </div>
                            <div style={{ flex: 1 }} />
                        </div>

                        {(currentVkladka !== 3 && currentVkladka !== 4 && isLoad && productButtons !== undefined) &&
                            <div style={{ display: 'flex', marginTop: 50 }} key={update}>
                                <div style={{ flex: 1 }} />
                                <div className={styles.text} style={{ flex: "1000px", width: 1000, display: 'flex', justifyContent: 'center' }}>
                                    <div key={productButtons} style={{ width: 1200 }}>
                                        {productButtons?.map((x, i) => {
                                            if (i === currentVkladka) {
                                                return (<div >
                                                    <SlateEditor content={productButtons[currentVkladka]?.contentEditor} setContent={(y) => {
                                                        const mem = [...productButtons]
                                                        mem[currentVkladka].contentEditor = y
                                                        setProductButtons(mem)
                                                    }} />
                                                </div>)
                                            }
                                        })}
                                    </div>
                                </div>
                                <div style={{ flex: 1 }} />
                            </div>
                        }

                        {currentVkladka === 3 &&
                            <div style={{ display: 'flex', marginTop: 50 }}>
                                <div style={{ flex: 1 }} />
                                <div className={styles.text} style={{ flex: "1000px", marginLeft: 20 }}>
                                    <div>
                                        <div className={styles.productTextHeader}>Скачать программное обеспечение для цифровых лабораторий</div>
                                        <div className={styles.productText}>Здесь вы сможете загрузить актуальную версию ПО для наших цифровых лабораторий на сегодняшний день. Для скачивания ПО выберете вашу операционную систему и загрузите файл. </div>
                                        <div style={{ height: 1, background: '#9E9E9E', marginTop: 40 }} />
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            <div>
                                                <div className={styles.poTextWrapper + ' ' + styles.productText}>
                                                    <div>Программное обеспечение для ПК</div>
                                                </div>
                                                <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 10 }}>
                                                    <div style={{ marginTop: 20 }} ><DownloadButtonOS icon={windows} text={"Windows"} text2={"Загрузить"} width={177} extension={188} link="nj8XwXDgw9AQQzxTGzUALvjIofLd4HuppM5rPN5N1EocZshm7QgEcmmKGeqdCdxQ.zip" /></div>
                                                    <div style={{ width: 20 }} />
                                                    <div style={{ marginTop: 20 }}><DownloadButtonOS icon={linux} text={"Linux"} text2={"Загрузить"} width={177} extension={188} link="irGko4Urfpoy5ZbDui4JAW58k8l5hA9bmx8js6dXAXwvyrbiPg2LkeI3P6cn1f22.zip" /></div>
                                                    <div style={{ width: 20 }} />
                                                </div>
                                            </div>
                                            <div className={styles.flexMagic} />
                                            <div style={{}}>
                                                <div className={styles.poTextWrapper + ' ' + styles.productText}>
                                                    <div>Установить приложение из Google play</div>
                                                </div>
                                                <div style={{ display: 'flex', flexWrap: 'wrap', marginTop: 10 }}>
                                                    <div style={{ marginTop: 20 }}><DownloadButtonOS icon={google} text={"Google play"} text2={"Загрузить"} width={177} extension={188} link="ckrT55zJigkIn7xOw4z7P6U8l3k5aJ3yjbT0O53M6GabczDPWtJNVc8S926zo80T.zip" /></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', alignItems: 'end', marginTop: 30, flexWrap: 'wrap' }}>
                                            <div>Скачать руководство пользователя (PDF файл):</div>
                                            <div style={{ width: 20 }} />
                                            <a style={{ marginTop: 20 }} href={address + "/uploads/JgfKyrBvtla9PLm9lJuRzMYcjDrSBGBYgngDb1ZcFqRF2J0o2WIvtbNyDufa2vEB.pdf"} target="_blank"><DownloadButton width={310} extension={320} text={"Руководство пользователя"} /></a>
                                        </div>
                                        <div style={{ display: 'flex', flexWrap: 'wrap' }}>
                                            <div className={styles.productText} style={{ flex: "500px", marginRight: 20, marginTop: 30 }}>
                                                Если возникли затруднения с установкой ПО, появились вопросы или предложения, Вы всегда можете обратиться к отделу нашей поддержки клиентов. Мы с радостью Вам поможем и ответим на все возникающие у Вас вопросы.
                                            </div>
                                            <div className={styles.supportWrapper}>
                                                <div className={styles.supportText}>
                                                    <span style={{ marginRight: 3 }}>Поддержка:</span><a className={styles.link} href="mailto:support@tkpolus.ru">support@tkpolus.ru</a><br />
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div style={{ flex: 1 }} />
                            </div>
                        }

                        {currentVkladka === 4 &&
                            <div style={{ display: 'flex', marginTop: 50, marginBottom: 50 }}>
                                <div style={{ flex: 1 }} />
                                <div className={styles.text} style={{ flex: "1000px", marginLeft: 20 }}>
                                    <div>
                                        <div style={{ display: 'flex' }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                Сертификат соответствия  Евразийский экономический союз:
                                            </div>
                                            <div style={{ marginLeft: 20 }}>
                                                <a href={address + "/uploads/4HsVPz0rWb7FNzorVN4E7FTIolKsqGUPEAqQ6kTvBCDYJWMGdppRRSUxmuqoLkit.pdf"} target="_blank"><DownloadButton width={210} extension={220} text={"Загрузить"} /></a>
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', marginTop: 20 }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                Сертификат соответствия ГОСТ Р:
                                            </div>
                                            <div style={{ marginLeft: 20 }}>
                                                <a href={address + "/uploads/29Xpl8NsDEtdteklXr2ylPWturvWuGIFdIHYKSgBUEjJzdOn0THIjW0jI5xwqgDq.pdf"} target="_blank"><DownloadButton width={210} extension={220} text={"Загрузить"} /></a>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div style={{ flex: 1 }} />
                            </div>
                        }

                        <div className={styles.saveButtonWrapper}>
                            <div className={styles.saveButton} onClick={() => changeStatus()}>
                                {status ? "Вернуть на доработку" : "Опубликовать"}
                            </div>
                            <div className={styles.saveButton} style={{ marginLeft: 20 }} onClick={() => saveHandler()}>
                                Сохранить
                            </div>
                        </div>

                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                            <div className={styles.footerWrapper} style={{ zIndex: 100 }}>
                                {width > 480 &&
                                    <div style={{ display: 'flex', maxWidth: 1110 }}>
                                        <div style={{ display: 'flex', alignItems: 'center' }}>
                                            <LazyLoadImage
                                                src={exclamation}
                                                effect="blur"
                                            />
                                        </div>
                                        <div className={styles.warningText}>
                                            Убедительная просьба, при покупке учебного оборудования согласовывать важные для вас характеристики, комплектацию и цену учебного оборудования.
                                        </div>
                                        <div onClick={() => { navigate("/customers"); window.scrollTo(0, 0); }} style={{ width: 208, display: 'flex', alignItems: 'center' }}>
                                            <TransparentButton width={198} extension={208} text={"Заказчикам"} />
                                        </div>
                                    </div>}
                                {width <= 480 &&
                                    <div style={{ maxWidth: 1110 }}>
                                        <div style={{ display: 'flex', margin: 18 }}>
                                            <div style={{ display: 'flex', alignItems: 'center' }}>
                                                <LazyLoadImage
                                                    src={exclamation}
                                                    effect="blur"
                                                />
                                            </div>
                                            <div className={styles.warningText}>
                                                Убедительная просьба, при покупке учебного оборудования согласовывать важные для вас характеристики, комплектацию и цену учебного оборудования.
                                            </div>
                                        </div>
                                        <div style={{ display: 'flex', justifyContent: 'center' }}>
                                            <div onClick={() => { navigate("/customers"); window.scrollTo(0, 0); }} style={{ width: 345, display: 'flex', alignItems: 'center', marginBottom: 30 }}>
                                                <TransparentButton width={335} extension={345} text={"Заказчикам"} />
                                            </div>
                                        </div>
                                    </div>}
                            </div>
                        </div>
                    </div >
                </div>
            }
            {isPreviewEditor &&
                <div>
                    <PreviewEditor setIsPreviewEditor={setIsPreviewEditor} />
                </div>
            }
        </div>

    );
}

export default ProductBuilder;
