import './App.css';
import PageWrapper from './Components/PageWrapper/PageWrapper';
import {
  BrowserRouter as Router,
  Route, Routes, Navigate,
} from "react-router-dom";
import axios from "axios";
import NotFound from './Components/NotFound/NotFound';
import  { Redirect } from 'react-router-dom'
import Uav from './Components/Uav/Uav';
axios.interceptors.request.use(
  config => {
    config.headers['Authorization'] = `Bearer ${sessionStorage.getItem('token')}`;
    return config;
  },
  error => {
    return Promise.reject(error);
  }
);

function App() {
  return (
    <div>
      <Router>
        <div>
          <Routes>
            {/* <Route exact path='/' element={<Navigate to="/main" />}></Route> Это пример редиректа */}
            <Route exact path='/' element={<PageWrapper />}></Route>
            <Route exact path='/news' element={<PageWrapper />}></Route>
            <Route exact path='/news/:id' element={<PageWrapper />}></Route>
            <Route exact path='/news_view' element={<Navigate to="/" />}></Route>
            <Route exact path='/news_view/:id' element={<PageWrapper />}></Route>
            <Route exact path='/products' element={<PageWrapper />}></Route>
            <Route exact path='/customers' element={<PageWrapper />}></Route>
            <Route exact path='/contact' element={<PageWrapper />}></Route>
            <Route exact path='/about' element={<PageWrapper />}></Route>
            <Route exact path='/order' element={<PageWrapper />}></Route>
            <Route exact path='/price' element={<PageWrapper />}></Route>
            <Route exact path='/moderation' element={<PageWrapper />}></Route>
            <Route exact path='/page_builder/:id' element={<PageWrapper />}></Route>
            <Route exact path='/page_builder' element={<Navigate to="/" />}></Route>
            <Route exact path='/product_builder/:id' element={<PageWrapper />}></Route>
            <Route exact path='/product_builder' element={<Navigate to="/" />}></Route>
            <Route exact path='/news_builder/:id' element={<PageWrapper />}></Route>
            <Route exact path='/news_builder' element={<Navigate to="/" />}></Route>
            <Route exact path='/product_page/:id' element={<PageWrapper />}></Route>
            <Route exact path='/product_page' element={<Navigate to="/" />}></Route>
            <Route exact path='/order804' element={<PageWrapper />}></Route>
            <Route exact path='/uav' element={<PageWrapper />}></Route>
            <Route exact path='/404' element={<div/>}></Route>
            <Route exact path='*' element={<NotFound/>}></Route>
          </Routes>
        </div>
        
      </Router>
      
    </div>
  );
}


export default App;
